<template>
    <div v-if="showActions || _isNew || comments.length > 0" class="p-2" :class="class" ref="topEl" v-bind="$attrs">
        <div class="d-flex justify-content-center align-items-center gap-3" v-if="showActions"> 
            <div class="flex-shrink-0 fw-medium" style="font-size: 1.125em;" v-if="showTitle">
                {{ $t("Journal") }}
            </div>
            <div class="flex-shrink-0">
                <button type="button" class="btn btn-link btn-sm dropdown-toggle ps-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{$t("Add Content")}}
                </button>
                <div class="dropdown-menu">
                    <button class="dropdown-item" @click="newItem()">{{$t("Action / Comment")}}</button>
                    <button class="dropdown-item" @click="showEmailDialog(props.ownerid)">{{$t("Email")}}</button>
                    <OFileUploadButton :dataObject="props.dataobject" :beforeUpload="() => beforeUpload(props.dataobject.current)" >
                        <button class="dropdown-item">{{$t("Attachment")}}</button>
                    </OFileUploadButton>
                </div>
            </div>
            <div class="flex-grow-1" v-if="showTitle">
            </div>
        </div>
        <div class="mt-2" v-if="showTitle">
        </div>
        <JournalItem 
            :ownerid="props.ownerid" 
            :ownerField="ownerField"
            @cancel="doCancel"             
            v-if="_isNew" 
            isNew 
            :isVerification="isVerification" 
            :parentCommentId="parentCommentId" 
            :parentCommentField="parentCommentField"
            :parentCommentParentField="parentCommentParentField"
            :dataobject="props.dataobject" 
            class="mb-3" 
            ref="newJournal" 
            :showActions="showActions"
            :sendEmailProcedure="sendEmailProcedure"
            :sendReminderProcedure="sendReminderProcedure"
            :isClientWorkspace="props.isClientWorkspace"
            :personDataObject="props.personDataObject"            
            :offline="offline" />
        <template v-for="(comment, index) in comments" :key="comment.ID || comment.PrimKey">
            <JournalItem 
                :ownerid="props.ownerid" 
                :ownerField="ownerField"
                :allowEdit="props.allowEdit" 
                :isVerification="isVerification" 
                v-if="!comment.isNewRecord" 
                :comment="comment" 
                :parentCommentId="parentCommentId" 
                :parentCommentField="parentCommentField"
                :parentCommentParentField="parentCommentParentField"
                :sendReminderProcedure="sendReminderProcedure"
                :dataobject="props.dataobject" 
                :personDataObject="props.personDataObject"                 
                :index="index" 
                :showActions="showActions"
                :sendEmailProcedure="sendEmailProcedure"
                :isClientWorkspace="props.isClientWorkspace"
                :offline="offline"
                :class='{ "col-auto" : comment.IsImage, "col-12": !comment.IsImage }'
                style="min-width:200px;"
                class="mb-3" />
        </template>
    </div>

    <OModal ref="emailDlg">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("New Email Message") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <div class="col-12">
                        <label class="text-muted">{{ $t("Recipients") }}:</label>
                        <div>
                            <OPersonsLookup v-if="persons.length == 0" :data-object="props.personDataObject"
                                :bind="sel => { addPerson(sel); }"
                                noClear>
                                <template #target="{target}">
                                    <div :ref="target" class="text-muted">
                                        {{ $t("Click to add recipients") }}
                                    </div>
                                </template>
                            </OPersonsLookup>
                            <div v-else>
                                <span v-for="person in persons" class="border mt-2 d-inline-block p-1 me-2">
                                    <OPersonsLookup :data-object="props.personDataObject"
                                        :bind="sel => { addPerson(sel); }"
                                        noClear>
                                        <template #target="{target}">
                                            <span :ref="target">
                                                {{ person.name }}
                                                <i class="bi bi-x ps-2 pe-1 text-muted" style="cursor: pointer;" @click="removePerson(person, $event)"></i>
                                            </span>
                                        </template>
                                    </OPersonsLookup>
                                </span>
                                <OPersonsLookup :data-object="props.personDataObject"
                                    :bind="sel => { addPerson(sel); }"
                                    noClear>
                                    <template #target="{target}">
                                        <button :ref="target" class="d-inline-block btn btn-link" style="cursor: pointer; text-decoration: none;">{{ $t("Add Recipient") }}</button> 
                                    </template>
                                </OPersonsLookup>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-2">
                        <div class="border-top">
                            <label class="text-muted">{{ $t("Subject") }}:</label>
                            <input class="form-control border-0 p-0 m-0" v-model="subject" style="border-radius: 0; box-shadow: none;">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="border-top">
                            <div class="my-2">
                                URL: <a :href="props.url()">
                                    {{ props.url() }}
                                </a>
                            </div>
                            <OTextArea class="form-control p-2 m-0" v-model="emailBody" style="border-radius: 0; box-shadow: none;" autoGrow noResize />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="createAndSend()">{{ $t("Send") }}</button>
                </div>
            </div>
        </div>
    </OModal>      
</template>

<script setup>
    import JournalItem from './UI.Journal.JournalItem.vue';
    import { computed, ref, defineExpose } from 'vue';
    import { OFileUploadButton } from 'o365-fileupload';
    import { getOrCreateProcedure, userSession } from 'o365-modules';
    import { OPersonsLookup } from 'o365-system-lookups';
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import { alert } from 'o365-vue-services';

    const props = defineProps({
        ownerid: { type: [Number, String], default: null },
        ownerField: { type: String, default: "Step_ID" },
        parentCommentId: { type: [Number, String] },
        parentCommentField: { type: String, default: "ParentComment_ID" },
        parentCommentParentField: { type: String, default: "ID" },
        dataobject: Object,
        allowEdit: Boolean,
        personDataObject: Object,
        showActions: { type: Boolean, default: false },
        canAdd: { type: Boolean, default: true },
        isVerification: Boolean,
        sendEmailProcedure: { type: String, default: null },
        sendReminderProcedure: { type: String, default: null },
        isNew: Boolean,
        url: { type: Function, default: () => location.href },
        isClientWorkspace: Boolean,
        showTitle: Boolean,
        class: null,
        offline: { type: Boolean, default: false },        
    });

    const procRecipientsSend = getOrCreateProcedure({ id: "procRecipientsSend-" + crypto.randomUUID(), procedureName: props.sendEmailProcedure });

    const persons = ref([]);
    const emailDlg = ref(null);
    const subject = ref(null);
    const emailBody = ref(null);
    const ownerIdProvided = ref(null);
    const topEl = ref(null);

    function showEmailDialog(ownerId){
        ownerIdProvided.value = ownerId;
        emailDlg.value.show();
    }

    function addPerson(person){
        var existingIndex = persons.value.findIndex(x => x.id == person.ID);
        if(existingIndex !== -1){
            return;
        }
        persons.value.push({
            id: person.ID,
            name: person.Name,
            email: person.Email
        });
    }

    function removePerson(person, event){
        event.stopPropagation();
        event.preventDefault();
        var indexToRemove = persons.value.findIndex(x => {
            return x.id == person.id; 
        });
        persons.value.splice(indexToRemove, 1);
    }    
    
    async function createAndSend(){
        if (!persons.value.length){
            alert($t("Please add recipients before sending"), undefined, { autohide: true, delay: 5000 });
            return;
        }
        var obj = new Object();
        obj[props.ownerField] = ownerIdProvided.value;
        obj.Title = subject.value;
        obj.Comment = emailBody.value;

        props.dataobject.createNew(obj, false);
        var res = await props.dataobject.save();
        var newCommentID = res[0][0].ID;
        await procRecipientsSend.execute({
            Comment_ID: newCommentID,
            Receivers: persons.value.map(x => [x.id]),
            URL: props.url()
        });

        persons.value.splice(0, persons.value.length);
        emailBody.value = "";
        subject.value = "";
        setTimeout(() => props.dataobject.load(), 0);
        emailDlg.value.hide();
    }

    const comments = computed(() => {
        return props.dataobject.data.filter(x => x[props.ownerField] == props.ownerid && x[props.parentCommentField] === (props.parentCommentId ? props.parentCommentId : null));     
    });   

    useDataObjectEventListener(props.dataobject, 'AfterSave', () => {
        _isNew.value = false;
    });

    const _isNew = ref(props.isNew);
    const newJournal = ref(null);
    const isVerification = ref(false);

    function doCancel(){
        _isNew.value = false;
    }

    function newItem(pIsVerification){
        isVerification.value = pIsVerification;
        _isNew.value = true;
        setTimeout(function(){
            newJournal.value.scrollIntoView();
        }, 0);
    }

    const beforeUpload = function(step){
        var obj = new Object();
        obj[props.ownerField] = props.ownerid;
        obj[props.parentCommentField] = props.parentCommentId ?? null;
        return obj;
    }    
    
    defineExpose({
        newItem,
        showEmailDialog
    });

</script>

